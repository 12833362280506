import './App.css';
import { WeatherContextProvider } from './core/WeatherDataContext';
import CityListComponent from './components/CityList/CityList';
import TopBarComponent from './components/TopBar/TopBar';

function App() {
  return (
    <WeatherContextProvider>
      <TopBarComponent />
      <div className="main-content">
        <CityListComponent />
      </div>
    </WeatherContextProvider>
  );
}

export default App;
