export default class Level {
    level: number;
    date: Date;

    constructor(level: number, date: Date) {
        this.level = level;
        this.date = date;
    }

    getLevel(): number {
        return this.level;
    }

    getDate(): Date {
        return this.date;
    }
}
