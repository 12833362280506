export default class Settings {

    static getSavedCities() : string[] {
        const savedfavoriteLocations = localStorage.getItem('selectedLocations');
        let favoriteLocations: string[] = [];
        if (savedfavoriteLocations) {
            favoriteLocations = JSON.parse(savedfavoriteLocations);
        } else {
            favoriteLocations = ['Timbó'];
            localStorage.setItem('selectedLocations', JSON.stringify(favoriteLocations));
        }
        return favoriteLocations;
    }

    static saveCities(cities: string[]) {
        localStorage.setItem('selectedLocations', JSON.stringify(cities));
    }

    static addCity(city: string) {
        const savedCities = this.getSavedCities();
        if (!savedCities.includes(city)) {
            savedCities.push(city);
        }
        this.saveCities(savedCities);
    }

    static removeCity(city: string) {
        const savedCities = this.getSavedCities();
        const newCities = savedCities.filter(c => c != city);
        this.saveCities(newCities);
    }

    static getFavoriteCities() {
        const savedFavoriteLocations = localStorage.getItem('favoriteLocations');
        let favoriteLocations: string[] = [];
        if (savedFavoriteLocations) {
            favoriteLocations = JSON.parse(savedFavoriteLocations);
        } else {
            favoriteLocations = [];
            localStorage.setItem('favoriteLocations', JSON.stringify(favoriteLocations));
        }
        return favoriteLocations;
    }

    static addFavoriteCity(city: string) {
        const favorites = this.getFavoriteCities();
        if (!favorites.includes(city)) {
            favorites.push(city);
        }
        localStorage.setItem('favoriteLocations', JSON.stringify(favorites));
    }

    static removeFavoriteCity(city: string) {
        const favorites = this.getFavoriteCities();
        const newFavorites = favorites.filter((c) => c != city);
        localStorage.setItem('favoriteLocations', JSON.stringify(newFavorites));
    }
}
