import React from 'react';
import CityCardComponent from '../CityCard/CityCard';
import { WeatherContext } from '../../core/WeatherDataContext';
import Settings from '../../core/Settings';
import WeatherData from '../../core/WeatherData';

interface CityList {
    // Define your component's props here
}

interface CityListComponentState {
    cities: string[],
    favoriteCities: string[]
}

export default class CityListComponent extends React.Component<CityList, CityListComponentState> {
    static contextType = WeatherContext;

    context!: React.ContextType<typeof WeatherContext>;


    // declare context: React.ContextType<typeof WeatherContext>

    constructor(props: CityList) {
        super(props);
        this.state = {
            favoriteCities: Settings.getFavoriteCities(),
            cities: Settings.getSavedCities()
        }
    }

    toggleFavorite = (city: string, favorite: boolean) => {
        console.log(city, favorite);
        if (favorite) {
            Settings.addFavoriteCity(city);
        } else {
            Settings.removeFavoriteCity(city);
        }
        // this.context?.refresh();
        this.setState({ favoriteCities: Settings.getFavoriteCities() });
    }

    render() {
        const weatherData = this.context?.value;
        const cities = Settings.getSavedCities();
        const favoriteCities = this.state.favoriteCities;

        const cityCards = weatherData
            ?.sort((w1, w2) => {
                const c1fav = favoriteCities.includes(w1.getName());
                const c2fav = favoriteCities.includes(w2.getName());
                if (c1fav && !c2fav) {
                    return -1;
                }
                if (!c1fav && c2fav) {
                    return 1;
                }
                return w1.getName().localeCompare(w2.getName());
            })
            ?.filter((data: WeatherData) => cities.includes(data.getName()))
            .map((weather) => {
                return <CityCardComponent key={weather.getName()} weatherData={weather} isFavorite={favoriteCities.includes(weather.getName())} favoriteCallback={this.toggleFavorite}></CityCardComponent>
            });
        return (
            <div className="columns is-multiline">
                {cityCards}
            </div>
        );
    }
}
