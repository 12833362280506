import React from 'react';
import WeatherData from '../../core/WeatherData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudRain, faDroplet, faTemperatureQuarter, faWater, faUpLong, faDownLong, faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import './CityCard.css';


interface CityCard {
    // Define your component's props here
    weatherData: WeatherData,
    isFavorite: boolean,
    favoriteCallback: any
}

interface CityCardComponentState {
    // Define your component's state properties here
}

export default class CityCardComponent extends React.Component<CityCard, CityCardComponentState> {
    constructor(props: CityCard) {
        super(props);

        this.state = {
            // Initialize your component's state here
        };
    }

    toggleFavorite = () => {
        this.props.favoriteCallback(this.props.weatherData.getName(), !this.props.isFavorite);
    }

    render() {
        const rain = this.props.weatherData.getCurrentRain();
        const humidity = this.props.weatherData.getHumidity();
        const riverLevel = this.props.weatherData.getRiverLevel();
        const temperature = this.props.weatherData.getTemperature();
        const temperatureFeel = this.props.weatherData.getTemperatureFeel();
        const levelChange = this.props.weatherData.getLevelDifference();
        const levelIcon = levelChange > 0 ? <FontAwesomeIcon color='red' icon={faUpLong}></FontAwesomeIcon> : <FontAwesomeIcon color='green' icon={faDownLong}></FontAwesomeIcon>;
        const showDiff = typeof riverLevel == 'number' ? 'block' : 'none';
        const icon = this.props.isFavorite ? faStar : farStar;

        return (
            <div className="column is-one-third">
                <div className="box">
                    <div className="city-header">
                        <div className="city-name is-size-4">{this.props.weatherData.getName()}</div>
                        <FontAwesomeIcon color='gold' className="toggle-favorite" icon={icon} onClick={this.toggleFavorite}></FontAwesomeIcon>
                    </div>
                    <div className="metrics columns is-mobile">
                        <div className="column is-half metric rain-container">
                            <div className="is-flex is-flex-direction-column">
                                <FontAwesomeIcon color='gray' size='2x' icon={faCloudRain} /> {/* Use the FontAwesomeIcon component */}
                                <span>{typeof rain == 'number' ? rain.toFixed(1) : '-'} mm</span>
                            </div>
                        </div>
                        <div className="column is-half metric humidity-container">
                            <div className="is-flex is-flex-direction-column">
                                <FontAwesomeIcon color='blue' size='2x' icon={faDroplet} /> {/* Use the FontAwesomeIcon component */}
                                <span>{humidity ? (humidity * 100).toFixed(0) : '-'} %</span>
                            </div>
                        </div>
                    </div>
                    <div className="metrics columns is-mobile">
                        <div className="column is-half metric temperature-container">
                            <div className="is-flex is-flex-direction-column">
                                <FontAwesomeIcon color='orange' size='2x' icon={faTemperatureQuarter} /> {/* Use the FontAwesomeIcon component */}
                                <span>{typeof temperature == 'number' ? temperature.toFixed(1) : '-'} ºC</span>
                                <span className="is-size-7">{typeof temperatureFeel == 'number' ? temperatureFeel.toFixed(1) : '-'} ºC</span>
                                <span className="is-size-7">(sensação)</span>
                            </div>
                        </div>
                        <div className="column is-half metric river-container">
                            <div className="is-flex is-flex-direction-column">
                                <FontAwesomeIcon color='lightblue' size='2x' icon={faWater} /> {/* Use the FontAwesomeIcon component */}
                                <span>{typeof riverLevel == 'number' ? riverLevel.toFixed(2) : '-'} m</span>
                                <div className="level-diff" style={{display: showDiff}}>{levelIcon}<span>{levelChange.toFixed(2)} m</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
