import React from 'react';
import { WeatherContext } from '../../core/WeatherDataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faGear } from '@fortawesome/free-solid-svg-icons';
import './TopBar.css';
import CitySetting from '../CitySetting/CitySetting';

interface TopBar {
    // Define your component's props here
}

interface TopBarComponentState {
    // Define your component's state properties here
}

export default class TopBarComponent extends React.Component<TopBar, TopBarComponentState> {
    static contextType = WeatherContext;

    context!: React.ContextType<typeof WeatherContext>;

    constructor(props: TopBar) {
        super(props);

        this.state = {
            // Initialize your component's state here
        };
    }

    refreshData = () => {
        this.context?.refresh();
        document.getElementById('refresh-button')?.classList.add('spinner');
        setTimeout(() => document.getElementById('refresh-button')?.classList.remove('spinner'), 2000);
    }

    openSettings = () => {
        document.getElementById('settings-modal')?.classList.add('is-active');
    }

    cancel = () => {
        document.getElementById('settings-modal')?.classList.remove('is-active');
    }

    render() {
        const citySettings = this.context?.value.map((data) => {
            return <CitySetting key={data.getName()} name={data.getName()}></CitySetting>
        });
        const lastUpdate = localStorage.getItem('lastUpdate') || '0';
        const date = new Date(parseInt(lastUpdate, 10) * 1000);

        return (
            <div className="box top-bar">
                <FontAwesomeIcon id="refresh-button" size='2x' icon={faRefresh} onClick={this.refreshData}></FontAwesomeIcon>
                <div className="columns is-gapless has-text-centered last-update">
                    <span className="column is-size-7">Última atualização</span>
                    <span className="column is-size-7">{date.toLocaleString()}</span>
                </div>
                <FontAwesomeIcon size='2x' icon={faGear} onClick={this.openSettings}></FontAwesomeIcon>
                <div id='settings-modal' className="modal">
                    <div className="modal-background" onClick={this.cancel}></div>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Selecionar Cidades</p>
                        </header>
                        <section className="modal-card-body">
                            {citySettings}
                        </section>
                        <footer className="modal-card-foot">
                            <button className="button" onClick={this.cancel}>Fechar</button>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}
