import React from 'react';
import Settings from '../../core/Settings';
import { useWeatherContext } from '../../core/WeatherDataContext';

// Define the props for your component
interface CitySettingProps {
    name: string;
}

// Define your function component
const CitySetting: React.FC<CitySettingProps> = ({ name }) => {
    const selectedCities = Settings.getSavedCities();
    const isChecked = selectedCities.includes(name);
    const weatherContext = useWeatherContext();

    const changeCity = (e: any) => {
        const {checked} = e.target;
        if (checked) {
            Settings.addCity(name);
        } else {
            Settings.removeCity(name);
        }
        weatherContext.refresh();
    }

    return (
        <div className="city-setting">
            <span>{name}</span>
            <label className="switch">
                <input type="checkbox" defaultChecked={isChecked} onChange={changeCity}/>
                <span className="slider round"></span>
            </label>
        </div>
    );
};

export default CitySetting;
