import Level from "./Level";

class WeatherData {
    name: string;
    currentRain: number;
    humidity: number;
    riverLevel: number;
    temperature: number;
    temperatureFeel: number;
    riverLevelHistory: Level[];

    constructor(name: string, currentRain: number, humidity: number, riverLevel: number, temperature: number, temperatureFeel: number) {
        this.name = name;
        this.currentRain = currentRain;
        this.humidity = humidity;
        this.riverLevel = riverLevel;
        this.temperature = temperature;
        this.temperatureFeel = temperatureFeel;
        this.riverLevelHistory = [];
    }

    setRiverLevels(levels: Level[]) {
        this.riverLevelHistory = levels;
    }

    getName(): string {
        return this.name;
    }

    getCurrentRain(): number {
        return this.currentRain;
    }

    getHumidity(): number {
        return this.humidity;
    }

    getRiverLevel(): number {
        return this.riverLevel;
    }

    getTemperature(): number {
        return this.temperature;
    }

    getTemperatureFeel(): number {
        return this.temperatureFeel;
    }

    getLevelDifference(): number {
        if (this.riverLevelHistory && this.riverLevelHistory.length > 0) {
            return this.riverLevel - this.riverLevelHistory[this.riverLevelHistory.length - 2].getLevel();
        }
        return 0;
    }
}

export default WeatherData;
