import { PropsWithChildren, createContext, useContext, useState, useEffect } from 'react';
import WeatherData from './WeatherData';
import DataProvider from './DataProvider';

interface WeatherContextState {
    value: WeatherData[];
    refresh: () => void;
}

// Create a context with an initial state
export const WeatherContext = createContext<WeatherContextState | undefined>(undefined);

// Create a provider component that sets and manages the context state
export const WeatherContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const dataProvider = new DataProvider();
    const [data, setData] = useState<WeatherData[]>([]);
    const [value, setValue] = useState<WeatherData[]>([]); // Initial state

    const refresh = () => {
        dataProvider.getData()
            .then((data) => {
                return data;
            })
            .then((apiData) => {
                setValue(apiData);
            })
            .catch((apiError) => {
            });
    };

    useEffect(() => {
        // Fetch data from your API here
        dataProvider.getData()
            .then((data) => {
                return data;
            })
            .then((apiData) => {
                setValue(apiData);
            })
            .catch((apiError) => {
                console.error(apiError);
            });
    }, []);

    return (
        <WeatherContext.Provider value={{ value, refresh }}>
            {children}
        </WeatherContext.Provider>
    );
};

// Create a custom hook to easily access the context in components
export const useWeatherContext = () => {
    const context = useContext(WeatherContext);

    if (context === undefined) {
        throw new Error('useWeatherContext must be used within a WeatherContextProvider');
    }

    return context;
};
