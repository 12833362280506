import { ApolloClient, useQuery, gql, InMemoryCache } from "@apollo/client";

import WeatherData from "./WeatherData";
import Level from "./Level";

const GET_DATA = gql`
        query {
            estacoes {
                nome
                chuva_001h
                umidade
                nivel_rio
                nivel_rio_historico {
                    nivel
                    t_stamp
                }
                temp_atual
                temp_sens
                tipo
            }
        }
    `;

export default class DataProvider {
    async getData(): Promise<WeatherData[]> {
        const client = new ApolloClient({
            uri: 'https://monitoramento.defesacivil.sc.gov.br/graphql',
            cache: new InMemoryCache()
        });
        const { loading, error, data } = await client.query({
            query: GET_DATA
        });
        const mappedData = this.mapData(data);

        localStorage.setItem('lastUpdate', Math.floor(new Date().getTime() / 1000).toString());

        //const dataBlumenau = await fetch('https://alertablu.blumenau.sc.gov.br/static/data/nivel_oficial.json', {mode: 'no-cors'});
        //console.log(dataBlumenau);

        return Array.from(mappedData.entries())
            .filter(([key, value]) => value.tipo != 'Barragem')
            .sort((key1, key2) => key1[0].localeCompare(key2[0]))
            .map(([key, value]) => {
                const levelHistory = value.nivel_rio_historico ? value.nivel_rio_historico.map((h: any) => new Level(h.nivel, new Date(h.t_stamp))) : [];
                const weatherData = new WeatherData(key, value.chuva_001h, value.umidade, value.nivel_rio, value.temp_atual, value.temp_sens);
                weatherData.setRiverLevels(levelHistory);
                return weatherData;
            });
    }

    private mapData(data: any): Map<string, any> {
        const dataMap = new Map<string, any>;
        data.estacoes.forEach((obj: any) => {
            const regex = new RegExp('DCSC\\s([^\\d]+)\\s?\\d?', 'g');
            const results = regex.exec(obj.nome);
            const name = results ? results[1].trim() : '';
            if (results && !dataMap.has(name)) {
                dataMap.set(name, obj);
            } else {
                const allProps = dataMap.get(name);
                const newProps = Object.assign({}, allProps);
                if (obj.chuva_001h) {
                    newProps.chuva_001h = obj.chuva_001h;
                }
                if (obj.umidade) {
                    newProps.umidade = obj.umidade;
                }
                if (obj.nivel_rio) {
                    newProps.nivel_rio = obj.nivel_rio;
                }
                if (obj.temp_atual) {
                    newProps.temp_atual = obj.temp_atual;
                }
                if (obj.temp_sens) {
                    newProps.temp_sens = obj.temp_sens;
                }
                if (obj.nivel_rio_historico && obj.nivel_rio_historico[0].nivel) {
                    newProps.nivel_rio_historico = obj.nivel_rio_historico;
                }
                dataMap.set(name, newProps);
            }
        });
        return dataMap;
    }

}
